import React from "react";

const Social = () => {
  return (
    <div className="home__social">
      <a
        href="https://www.linkedin.com/in/axcasella/"
        className="home__social-icon"
        target="_blank" rel="noreferrer"
      >
        <i className="uil uil-linkedin"></i>
      </a>

      <a
        href="https://github.com/axcasella"
        className="home__social-icon"
        target="_blank" rel="noreferrer"
      >
        <i className="uil uil-github"></i>
      </a>

      <a
        href="https://scholar.google.com/citations?user=gTetcMoAAAAJ&hl=en"
        className="home__social-icon"
        target="_blank" rel="noreferrer"
      >
        <i className="uil uil-google"></i>
      </a>

    </div>
  );
};

export default Social;
