import React from "react";
import "./about.css";
import AboutImg from "../../assets/about.jpg";
import Info from "./Info";

const About = () => {
  return (
    <section className="about section" id="about">
      <h2 className="section__title">Overview</h2>
      <span className="section__subtitle"></span>

      <div className="about__container container grid">
        {/* <img src={AboutImg} alt="" className="about__img" /> */}

        <div className="about__data">
          {/* <Info /> */}
          <p className="about__description">
          I currently work at Thoughtly as Co-Founder and Head of Engineering. Thoughtly makes it easy for thousands of businesses to launch human-like voice AI agents that replace call centers. We work with publicly traded enterprises and SMBs across a range of industries including Healthcare, Real Estate, and Legal. At Thoughtly, I own all things engineering, leading application development, conversational AI, platform infrastructure, and ML ops. 
          </p>

          <p className="about__description">
          Before Thoughtly, I was Co-Founder and CTO of Nudgem, a no-code, AI-powered, onsite experience builder that brands use to create segmented experiences and storefronts to increase the lifetime value and conversion rate of their most valuable customers. I led the execution of product design and development. Nudgem was privately acquired in 2024. 
          </p>

          <p className="about__description">
          In addition to working at Nudgem, I also hosted a podcast (live on Spotify) called "Driving Forces of Tomorrow", where I interviewed leading experts and operators across multiple industries that leverage AI. 
          </p>

          <p className="about__description">
          Prior to Nudgem, I was an engineering manager at Bluecore, a unicorn tech startup that uses ML to power marketing personalization. Over 800 of the largest retailers and brands used the campaign management tools we built to orchestrate sending billions of emails and SMS messages to their customers. 
          </p>

          <p className="about__description">
          Before that, I was a tech lead at IBM Watson, building B2B SAAS for the healthcare blockchain division. We worked with large payers and providers, as well as the FDA and CDC to optimize the exchange of sensitive data for millions of patients in a HIPAA compliant way. 
          </p>
        </div>
      </div> 
    </section>
  );
};

export default About;
